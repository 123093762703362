// import React from 'react';
import React  from 'react';
import {firstContentImages , secondContentImages} from "./PartnerImages";

const Prtners = () => {

 const firstContentFirstRow = firstContentImages.slice(0, 9);
 const secondContentFirstRow = secondContentImages.slice(0, 9);
 const firstContentSecondRow = firstContentImages.slice(9);
 const secondContentSecondRow = secondContentImages.slice(9);
 
  return (
    <div className="partners">
        <div className="partners-main">
            <div className="list">
            <div className="first-list">
              <div className="list-l">
                <div className="title">
                  <p>에코센트레</p>
                  <p>포인트 사용 파트너 </p>
                </div>
                <div className="description">
                  <p>국내외 주요파트너사들이</p>
                  <p>에코센트레와 함께합니다.</p>
                </div>
              </div>
              <div className="list-r">
                {firstContentFirstRow.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
                  ))}
              </div>
            </div>
            <div className='second-list'>
            {firstContentSecondRow.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
            ))}
            </div> 


            <div className='mob-list'>
            {firstContentImages.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
            ))}
            </div>                          
            </div>

            <div className="list">
            <div className="first-list">
              <div className="list-l">
                <div className="title">
                  <p>에코센트레</p>
                  <p>환경 파트너</p>
                </div>
                <div className="description">
                  <p>국내외 주요파트너사들이</p>
                  <p>에코센트레와 함께합니다.</p>
                </div>
              </div>
              <div className="list-r">
              {secondContentFirstRow.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
                  ))}
              </div>
            </div>
            {/* {(isListVisible || !isMobile) && ( */}
            <div className='second-list'>
            {secondContentSecondRow.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
                  ))}
            </div>

             <div className="mob-list">
             {secondContentImages.map((image, index) => (
                  <div>
                    <img src={image} alt={`partner${index + 1}`} />
                  </div>
            ))}
             </div>


            {/* )} */}

            {/* {isMobile && (
              <div class="btn-block">
              <button className="view" onClick={toggleListVisibility}>
               {isListVisible ? 'View Less' : 'View More'}
             </button>
             </div>
            )} */}

            </div>
        </div>
    </div>
  )
}

export default Prtners