import React from "react";

function MThirdBanner() {
  return (
    <div className="mobile-third-banner">
      <div className="content">
        <h2>플라스틱히어로 애플리케이션</h2>
        <p>적립 받은 환경 포인트를 현금처럼 사용하세요</p>
      </div>
      <div className="arrow">
        <img src="/assets/icon/scroll.png" alt="scroll" />
      </div>
    </div>
  );
}

export default MThirdBanner;
