const firstContentImages = [
    "/assets/image/partnerlogo/firstcont/1.png",
    "/assets/image/partnerlogo/firstcont/2.png",
    "/assets/image/partnerlogo/firstcont/3.png",
    "/assets/image/partnerlogo/firstcont/4.png",
    "/assets/image/partnerlogo/firstcont/5.png",
    "/assets/image/partnerlogo/firstcont/6.png",
    "/assets/image/partnerlogo/firstcont/7.png",
    "/assets/image/partnerlogo/firstcont/8.png",
    "/assets/image/partnerlogo/firstcont/9.png",
    "/assets/image/partnerlogo/firstcont/10.png",
    "/assets/image/partnerlogo/firstcont/11.png",
    "/assets/image/partnerlogo/firstcont/12.png",
    "/assets/image/partnerlogo/firstcont/13.png",
    "/assets/image/partnerlogo/firstcont/14.png",
    "/assets/image/partnerlogo/firstcont/15.png",
    "/assets/image/partnerlogo/firstcont/16.png",
    "/assets/image/partnerlogo/firstcont/17.png",
    "/assets/image/partnerlogo/firstcont/18.png",
    "/assets/image/partnerlogo/firstcont/19.png",
    "/assets/image/partnerlogo/firstcont/20.png",
    "/assets/image/partnerlogo/firstcont/21.png",
    "/assets/image/partnerlogo/firstcont/22.png",
    "/assets/image/partnerlogo/firstcont/23.png",
    "/assets/image/partnerlogo/firstcont/24.png",
    "/assets/image/partnerlogo/firstcont/25.png",
    "/assets/image/partnerlogo/firstcont/26.png",
    "/assets/image/partnerlogo/firstcont/27.png",
    "/assets/image/partnerlogo/firstcont/28.png",
    "/assets/image/partnerlogo/firstcont/29.png",
    "/assets/image/partnerlogo/firstcont/30.png",
    "/assets/image/partnerlogo/firstcont/31.png",
    "/assets/image/partnerlogo/firstcont/32.png",
    "/assets/image/partnerlogo/firstcont/33.png",
    "/assets/image/partnerlogo/firstcont/34.png",
    "/assets/image/partnerlogo/firstcont/35.png",
    "/assets/image/partnerlogo/firstcont/36.png",
    "/assets/image/partnerlogo/firstcont/37.png",
    "/assets/image/partnerlogo/firstcont/38.png",
    "/assets/image/partnerlogo/firstcont/39.png",
    "/assets/image/partnerlogo/firstcont/40.png",
    "/assets/image/partnerlogo/firstcont/41.png",
    "/assets/image/partnerlogo/firstcont/42.png",
    "/assets/image/partnerlogo/firstcont/43.png",
    "/assets/image/partnerlogo/firstcont/44.png",
    "/assets/image/partnerlogo/firstcont/45.png",
    "/assets/image/partnerlogo/firstcont/46.png",
    "/assets/image/partnerlogo/firstcont/47.png",
    "/assets/image/partnerlogo/firstcont/48.png",
    "/assets/image/partnerlogo/firstcont/49.png",
    "/assets/image/partnerlogo/firstcont/50.png",
    "/assets/image/partnerlogo/firstcont/51.png",
    "/assets/image/partnerlogo/firstcont/52.png",
    "/assets/image/partnerlogo/firstcont/53.png",
    "/assets/image/partnerlogo/firstcont/54.png",
    "/assets/image/partnerlogo/firstcont/55.png",
    "/assets/image/partnerlogo/firstcont/56.png"
    
    // ... add more image paths
  ];


  const secondContentImages = [
    "/assets/image/partnerlogo/secondcont/1.png",
    "/assets/image/partnerlogo/secondcont/2.png",
    "/assets/image/partnerlogo/secondcont/3.png",
    "/assets/image/partnerlogo/secondcont/4.png",
    "/assets/image/partnerlogo/secondcont/5.png",
    "/assets/image/partnerlogo/secondcont/6.png",
    "/assets/image/partnerlogo/secondcont/7.png",
    "/assets/image/partnerlogo/secondcont/8.png",
    "/assets/image/partnerlogo/secondcont/9.png",
    "/assets/image/partnerlogo/secondcont/10.png",
    "/assets/image/partnerlogo/secondcont/11.png",
    "/assets/image/partnerlogo/secondcont/12.png",
    "/assets/image/partnerlogo/secondcont/13.png",
    "/assets/image/partnerlogo/secondcont/14.png",
    "/assets/image/partnerlogo/secondcont/15.png",
    "/assets/image/partnerlogo/secondcont/16.png",
    "/assets/image/partnerlogo/secondcont/17.png",
    "/assets/image/partnerlogo/secondcont/18.png",
    "/assets/image/partnerlogo/secondcont/19.png",
    "/assets/image/partnerlogo/secondcont/20.png",
    "/assets/image/partnerlogo/secondcont/21.png",
    "/assets/image/partnerlogo/secondcont/22.png",
    "/assets/image/partnerlogo/secondcont/23.png",
  ]


  export  {firstContentImages , secondContentImages};
