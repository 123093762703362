import React from "react";
import { useState } from "react";


function MFirstBanner() {
  const [popup, setPopup] = useState(true);

  return (
    <div className="mobile-first-banner">
    {/* {
      popup && <div class="popup" ><div class="popup-inner"><div class="close-btn" onClick={()=>( setPopup(false))}>X</div><div class="image-container"><img className="popup-image" src="/assets/image/popup.jpg" alt="popup"/></div></div></div>
    } */}
      <div className="content">
        <h2>
          이젠 버리지 말고
          <br />
          플라스틱히어로가 <br />
          되어주세요
        </h2>
      </div>
      <div className="app-btn">
        <button
          type="button"
          onClick={() => {
            window.open(
              "https://apps.apple.com/app/plastichero/id6471475390",
              "_blank"
            );
          }}
        >
          <img src="/assets/icon/applekorea.png" alt="applekorea" />
          App Store
        </button>
        <button
          type="button"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=io.ecocentre.app&pli=1",
              "_blank"
            );
          }}
        >
          <img src="/assets/icon/googleplay.png" alt="googleplay" />
          Google Play
        </button>
      </div>
      <div className="arrow">
        <img src="/assets/icon/scroll.png" alt="scroll" />
      </div>
    </div>
  );
}

export default MFirstBanner;
