import React from "react";
import { useState } from "react";

function Main() {

  const [popup, setPopup] = useState(true);

  return (
    <section className="main">
    {/* {
      popup && <div class="popup" ><div class="popup-inner"><div class="close-btn" onClick={()=>( setPopup(false))}>X</div><div class="image-container"><img className="popup-image" src="/assets/image/popup.jpg" alt="popup"/></div></div></div>
    } */}
      <div className="main-inner">
        <h2>
          이젠 버리지 말고 <br />
          플라스틱히어로가 <br />
          되어주세요
        </h2>
        <div className="app-btn">
          <button
            type="button"
            onClick={() => {
              window.open(
                "https://apps.apple.com/app/plastichero/id6471475390",
                "_blank"
              );
            }}
          >
            <img src="/assets/icon/applekorea.png" alt="applekorea" />
            App Store
          </button>
          <button
            type="button"
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=io.ecocentre.app&pli=1",
                "_blank"
              );
            }}
          >
            <img src="/assets/icon/googleplay.png" alt="googleplay" />
            Google Play
          </button>
        </div>
      </div>
    </section>
  );
}

export default Main;
