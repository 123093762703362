import React from "react";

function MFourBanner() {
  return (
    <div className="mobile-four-banner">
      <div className="content">
        <h2>ESG DATA</h2>
        <p>
          우리가 만드는 탄소 절감 효과
          <br />
          연간 33톤 이산화탄소 배출 억제
        </p>
      </div>
      <div className="arrow">
        <img src="/assets/icon/scroll.png" alt="scroll" />
      </div>
    </div>
  );
}

export default MFourBanner;
