import React from "react";

function MFiveBanner() {
  return (
    <div className="mobile-five-banner">
      <div className="content">
        <h2>“Be a Plastichero!”</h2>
        <p>이제 버리지 말고 Plastichero가되어주세요 </p>
      </div>
      <div className="arrow">
        <img src="/assets/icon/scroll.png" alt="scroll" />
      </div>
    </div>
  );
}

export default MFiveBanner;
