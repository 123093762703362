import React from "react";

function MSecondBanner() {
  return (
    <div className="mobile-second-banner">
      <div className="content">
        <h2>플라스틱히어로 AI 로봇</h2>
        <p>
          언제 어디서나 페트병을 수거하기 위해
          <br />
          항상 여러분 곁에 있습니다. 플라스틱히어로와
          <br />
          함께 환경을 살리고 포인트도 받아보세요!
        </p>
      </div>
      <div className="arrow">
        <img src="/assets/icon/scroll.png" alt="scroll" />
      </div>
    </div>
  );
}

export default MSecondBanner;
